<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Merchant Rates</span>
                <span class="headline" v-else>Create Merchant Rates</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Id*"  :readonly="formType === 'UPDATE'" v-if="formType === 'UPDATE'" v-model="form.id" data-cy="form-id"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-autocomplete clearable chips :items="merchantList" item-text="name" item-value="id" label="Merchant*" v-model="form.merchant" required :rules="[rules.requiredField]" return-object data-cy="form-merchant">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Product Name*"  v-model="form.productName" required :rules="[rules.requiredField]" data-cy="form-product-name"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Percent Discount Rate (Use Decimal Format | Ex: 5% --> .05)"  v-model="form.percentCommission" @keypress="onlyForCurrency" data-cy="form-percent-commission"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Fixed Discount Rate"  v-model="form.fixedCommission" @keypress="onlyForCurrency" data-cy="form-fixed-commission"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Provider Percent Discount Rate (Use Decimal Format | Ex: 5% --> .05)"  v-model="form.providerPercentCommission" @keypress="onlyForCurrency" data-cy="form-provider-percent-commission"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Provider Fixed Discount Rate"  v-model="form.providerFixedCommission" @keypress="onlyForCurrency" data-cy="form-provider-fixed-commission"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                 <v-menu v-model="showApplicationDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="Application Date*" prepend-icon="mdi-calendar" readonly :value="displayApplicationDate" v-on="on" required :rules="[rules.requiredField]" data-cy="form-display-application-date"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="form.applicationDate" no-title @input="showApplicationDateMenu = false" ></v-date-picker>
                                </v-menu>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        merchants: Array
    },
    computed: {
        populatedForm() {
            return {
                id:this.selectedItem.id,
                merchant: this.form.merchant,
                productName: this.form.productName,
                percentCommission: this.form.percentCommission,
                fixedCommission: this.form.fixedCommission,
                providerPercentCommission: this.form.providerPercentCommission,
                providerFixedCommission: this.form.providerFixedCommission,
                applicationDate: this.form.applicationDate,
                formType: this.formType
            }
        },
        displayApplicationDate() {
            return this.form.applicationDate
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            merchantList: this.merchants ?? [],
            showApplicationDateMenu: false,
            form: {
                merchant: {}
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-merchant-commission', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    id: undefined,
                    merchant: undefined,
                    productName: undefined,
                    percentCommission: undefined,
                    fixedCommission: undefined,
                    providerPercentCommission: undefined,
                    providerFixedCommission: undefined,
                    applicationDate: undefined
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                console.log("update details:",this.selectedItem)
                this.form = {
                    id: this.selectedItem.id,
                    merchant: this.selectedItem.merchant,
                    productName: this.selectedItem.productName,
                    percentCommission: this.selectedItem.percentCommission,
                    fixedCommission: this.selectedItem.fixedCommission,
                    providerPercentCommission: this.selectedItem.providerPercentCommission,
                    providerFixedCommission: this.selectedItem.providerFixedCommission,
                    applicationDate: this.selectedItem.applicationDate
                }
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        onlyForCurrency ($event) {
            // console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            // only allow number and one dot
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.form.amount.indexOf('.') != -1)) { // 46 is dot
                $event.preventDefault();
            }

            // restrict to 2 decimal places
            if(this.form.amount!=null && this.form.amount.indexOf(".")>-1 && (this.form.amount.split('.')[1].length > 1)){
                $event.preventDefault();
            }
        },
        getStatus: async function (val) {
            console.log('val', val)
                this.form.status = val
        },
    }
}
</script>
